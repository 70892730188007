import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/icon/iconfont.js"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import  {VueJsonp} from 'vue-jsonp'
// 引入公共样式
import "./styles/index.css"
import '@/assets/font/font.css' // 字体
import VueResource from 'vue-resource';
Vue.config.productionTip = false


Vue.use(ElementUI);
 
Vue.use(VueJsonp)

Vue.use(VueResource)
// eslint-disable-next-line
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);  // 页面跳转，从头部开始
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
