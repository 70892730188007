import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/PC/index.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: index,
	},
	{
		path: '',
		name: 'index',
		component: index,
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('@/views/page_list/about.vue')
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('@/views/page_list/contact.vue')
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('@/views/page_list/contact.vue')
	},
	{
		path: '/solutions',
		name: 'solutions',
		component: () => import('@/views/solut_list/index.vue')
	},
	{
		path: '/support',
		name: 'support',
		component: () => import('@/views/page_list/support.vue')
	},
	{
		path: '/product',
		name: 'product',
		component: () => import('@/views/products/index.vue')
	},
	{
		path: '/services/tms',
		name: 'tms',
		component: () => import('@/views/services/TMS.vue')
	},
	{
		path: '/services/Mobile',
		name: 'Mobile',
		component: () => import('@/views/solut_list/solut_0.vue')
	},
	{
		path: '/solut-0',
		name: 'solut-0',
		component: () => import('@/views/solut_list/solut_0.vue')
	},
	{
		path: '/solut-1',
		name: 'solut-1',
		component: () => import('@/views/solut_list/solut_1.vue')
	},
	{
		path: '/solut-2',
		name: 'solut-2',
		component: () => import('@/views/solut_list/solut_2.vue')
	},
	{
		path: '/solut-3',
		name: 'solut-3',
		component: () => import('@/views/solut_list/solut_3.vue')
	},
	{
		path: '/KS8226',
		name: 'KS8226',
		component: () => import('@/views/products/KS8226.vue')
	},
	{
		path: '/KS8223',
		name: 'KS8223',
		component: () => import('@/views/products/KS8223.vue')
	},
	{
		path: '/ZT2214',
		name: 'ZT2214',
		component: () => import('@/views/products/ZT2214.vue')
	},
	{
		path: '/ZTS-CPS70',
		name: 'ZTS-CPS70',
		component: () => import('@/views/products/ZTS-CPS70.vue')
	},
	{
		path: '/ZT8669',
		name: 'ZT8669',
		component: () => import('@/views/products/ZT8669.vue')
	},
	{
		path: '/ZT260',
		name: 'ZT260',
		component: () => import('@/views/products/ZT260.vue')
	},
	{
		path: '/ZT8648',
		name: 'ZT8648',
		component: () => import('@/views/products/ZT8648.vue')
	},
	{
		path: '/ZT8303',
		name: 'ZT8303',
		component: () => import('@/views/products/ZT8303.vue')
	},
	{
		path: '/ZT8309',
		name: 'ZT8309',
		component: () => import('@/views/products/ZT8309.vue')
	},
	{
		path: '/KS8123Plus',
		name: 'KS8123Plus',
		component: () => import('@/views/products/KS8123Plus.vue')
	},
	{
		path: '/ZT8669Plus',
		name: 'ZT8669Plus',
		component: () => import('@/views/products/ZT8669Plus.vue')
	},
	{
		path: '/ZT8350',
		name: 'ZT8350',
		component: () => import('@/views/products/ZT8350.vue')
	},
	{
		path: '/ZT6240',
		name: 'ZT6240',
		component: () => import('@/views/products/ZT6240.vue')
	},
	{
		path: '/ZT6250',
		name: 'ZT6250',
		component: () => import('@/views/products/ZT6250.vue')
	},
	{
		path: '/ZT8351',
		name: 'ZT8351',
		component: () => import('@/views/products/ZT8351.vue')
	},
	{
		path: '/ZT588F',
		name: 'ZT588F',
		component: () => import('@/views/products/ZT588F.vue')
	},
	{
		path: '/ZT598L',
		name: 'ZT598L',
		component: () => import('@/views/products/ZT598L.vue')
	},
	{
		path: '/ZT598M',
		name: 'ZT598M',
		component: () => import('@/views/products/ZT598M.vue')
	},
	{
		path: '/ZT598H',
		name: 'ZT598H',
		component: () => import('@/views/products/ZT598H.vue')
	},
	{
		path: '/ZT598B',
		name: 'ZT598B',
		component: () => import('@/views/products/ZT598B.vue')
	},
	{
		path: '/ZT599N',
		name: 'ZT599N',
		component: () => import('@/views/products/ZT599N.vue')
	},
	{
		path: '/ZT599NM',
		name: 'ZT599NM',
		component: () => import('@/views/products/ZT599NM.vue')
	},
	{
		path: '/ZT8211',
		name: 'ZT8211',
		component: () => import('@/views/products/ZT8211.vue')
	},
	{
		path: '/ZT8212',
		name: 'ZT8212',
		component: () => import('@/views/products/ZT8212.vue')
	},
	{
		path: '/i90',
		name: 'i90',
		component: () => import('@/views/products/I90.vue')
	},
	{
		path: '/ZT2214',
		name: 'ZT2214',
		component: () => import('@/views/products/ZT2214.vue')
	},
	{
		path: '/ZTS-HR22',
		name: 'ZTS-HR22',
		component: () => import('@/views/products/ZTS-HR22.vue')
	},
	{
		path: '/ZTS-FR20',
		name: 'ZTS-FR20',
		component: () => import('@/views/products/ZTS-FR20.vue')
	},
	{
		path: '/ZT8213',
		name: 'ZT8213',
		component: () => import('@/views/products/ZT8213.vue')
	},
	{
		path: '/ZT2220',
		name: 'ZT2220',
		component: () => import('@/views/products/ZT2220.vue')
	},
	{
		path: '/ZT2618',
		name: 'ZT2618',
		component: () => import('@/views/products/ZT2618.vue')
	},
	{
		path: '/ZT2880',
		name: 'ZT2880',
		component: () => import('@/views/products/ZT2880.vue')
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('@/views/news/index.vue')
	},
]

const router = new VueRouter({
	routes
})

export default router
