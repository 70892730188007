import request from '@/utils/request';
import { KEY } from '@/utils/request';


// // 联系公司
// export const get_pointApi = (params) => {
//   return request.get('/ws/geocoder/v1/?',{params})
// }
// 
export const get_pointApi = (params) => {
    return request.get('/ws/geocoder/v1',{
            location:params,
			key:KEY
        })
}

//
export const post_company = (data) => {
  return request.post('push/email/pushEmail',data)
}

// 启动时候调用
export function createAPI() {
  return request({
    url: '/ops-dev-api/api/ows/visit/create',
    method: 'get',
  })
}


// 查询设备树
export function getTreeApi() {
  return request({
    url: '/ops-dev-api/api/ows/product/product-tree',
    method: 'get',
  })
}

// 查询设备树2层
export function getTreesApi() {
  return request({
    url: '/ops-dev-api/api/ows/product/product-tree-2',
    method: 'get',
  })
}

// 查询设备树
export function getCarouselApi() {
  return request({
    url: '/ops-dev-api/api/ows/carousel/list',
    method: 'get',
  })
}


// 查询新闻列表
export function getNews(params) {
  return request({
    url: '/ops-dev-api/api/ows/news/list',
    method: 'get',
	params:params
  })
}

// 查询新闻详情
export function getNewsDetail(params) {
  return request({
    url: '/ops-dev-api/api/ows/news/detail',
    method: 'get',
	params:params
  })
}

// 查询设备详情
export function getDeviceDetail(name) {
  return request({
    url: '/ops-dev-api/api/ows/product/info/' + name,
    method: 'get',
  })
}

// 查询设备列表
export function getDeviceList() {
  return request({
    url: '/ops-dev-api/api/ows/product/flat-list',
    method: 'get',
  })
}

// 查询设备列表
export function getVideo() {
  return request({
    url: '/ops-dev-api/api/ows/media/video/play/sample.mp4',
    method: 'get',
	responseType:'blob',
  })
}












