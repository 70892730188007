<template>
  <div >
    <router-view/>
  </div>
</template>

<style>
	*{
		margin: 0;
		padding: 0;
	}
</style>

	<script>
		import {
			createAPI
		} from '@/api/index';
		export default {
			created() {
				createAPI().then(res=>{
				})
			},
		}
		
	</script>

