import axios from 'axios'
import Vue from 'vue'
// import md5 from "js-md5"
// const base_url = "http://10.255.33.81"; //  
const base_url = "https://proxy.szzt.com.cn"; // 
Vue.prototype.api_base_url = base_url

const whats_url = "https://web.whatsapp.com/send?l=en&phone=8613570885185"
const line_url = "https://www.linkedin.com/company/szzt-electronics-shenzhen-co-ltd-/"
const ytb_url = "https://www.youtube.com/channel/UCS1mjL3NbTeaq3sbObIeD3A"
const skype_url = "skype:lucypeng0303?chat"
Vue.prototype.whats_url = whats_url
Vue.prototype.line_url = line_url
Vue.prototype.ytb_url = ytb_url
Vue.prototype.skype_url = skype_url

// const base_url = "https://apis.map.qq.com";

export const KEY = "HL5BZ-QUCYX-TRB42-T543Z-4LQBE-FYBFT";

const timestamp = parseInt(new Date().getTime() / 1000);


const instance = axios.create({
	baseURL: base_url,
	timeout: 4000
});

// 拦截器 - 请求拦截
instance.interceptors.request.use(config => {
	// 部分接口需要token
	let token = localStorage.getItem("token");
	if(token){
		config.headers = {
			"aa-token":token
		}
	}
	
	config.headers = {
		"timestamp": timestamp,
		'Content-Type':'application/json; charset=utf-8' //配置请求头
		// "sign": md5('szzt@eamil' + String(timestamp))
	}
	
	
	return config;
},err=>{
	return Promise.reject(err)
});

// 拦截器 - 响应拦截
instance.interceptors.request.use(res => {
	return res;
},err=>{
	return Promise.reject(err)
});

export default instance;
