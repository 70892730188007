<template>
	<div class="page_bottom">
		<div class="page_bottom_top">
			<div class="bot-left">
				<div class="bot-left-top">
					<div class="bot-left-top-left">
						<img class="bot-img" @click="open_line"  src="../assets/images/share_1.png" alt="" />
						<!-- <img class="bot-img" src="../assets/images/share_2.png" alt="" /> -->
						<img class="bot-img" @click="open_ytb" src="../assets/images/share_3.png" alt="" />
						<img class="bot-img" @click="open_whats"  src="../assets/images/share_9.png" alt="" />
						<img class="bot-img" @click="open_skype" src="../assets/images/share_10.png" alt="" />
					</div>
					<div class="bot-left-top-right">
						<!-- <img class="bot-img" src="../assets/images/share_4.png" alt="" /> -->
						<!-- <img class="bot-img" src="../assets/images/share_5.png" alt="" /> -->
					</div>
				</div>
				<div class="bot-left-line">
	
				</div>
				<div class="bot-left-bottom">
					<span class="bottom-spa" @click="go_index">Home</span>
					<span class="bottom-spa" @click="go_about">About Us</span>
					<span class="bottom-spa" @click="go_Solutions" >Solutions</span>
					<span class="bottom-spa" @click="go_product" >Products</span>
					<span class="bottom-spa" @click="go_support">Support</span>
					<span class="bottom-spa" @click="go_contact" >Contact Us</span>
				</div>
			</div>
			<div class="bot-right">
				<div class="bot-right-item">
					<div class="left-icon">
						<img class="left-icon-img" src="../assets/images/share_6.png" alt="" />
					</div>
					<div class="right-content">
						Tel: +86 0755-81728888
					</div>
				</div>
				<div class="bot-right-item">
					<div class="left-icon">
						<img class="left-icon-img" src="../assets/images/share_7.png" alt="" />
					</div>
					<div class="right-content">
						E-mail: marketing@szzt.com.cn
					</div>
				</div>
				<div class="bot-right-item">
					<div class="left-icon">
						<img class="left-icon-img" src="../assets/images/share_8.png" alt="" />
					</div>
					<div class="right-content">
						Add: SZZT Industrial Park, No.3Tongguan Rd, Guangming District,Shenzhen, Guangdong,
						China
					</div>
				</div>
			</div>
		</div>
		<div class="page_bottom_center" @click="">
			<span>Copyright&nbsp;</span>
			<span>©</span>
			<span>&nbsp;SZZT&nbsp;Electronics&nbsp;Co.,Ltd&nbsp;All&nbsp;Rights&nbsp;Reserved.</span>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'PageBottom',
		props: ["show_num"],
		created() {

		},
		data() {
			return {
				currentSwiper: null,
				isMobile: false,
				
			}
		},
		watch: {
			show_num: {
				deep: true,
				// eslint-disable-next-line
				handler(nv, ov) {
					console.log("数据更新了")
					// this.updateSwiper()
				}
			}
		},
		mounted() {
			// var _this = this;
			// setTimeout(function() {
			// 	_this.initSwiper()
			// }, 300)
		},
		methods: {
			open_line(){
				window.open(this.line_url)
			},
			open_whats(){
				window.open(this.whats_url)
			},
			open_ytb(){
				window.open(this.ytb_url)
			},
			open_skype(){
				window.open(this.skype_url)
			},
			go_index() {
				this.$router.push({
					path: '/',
				})
			},
			go_about() {
				this.$router.push({
					path: '/about',
				})
			},
			go_Solutions(){
				this.$router.push({
					path: '/solutions',
				})
			},
			go_support() {
				this.$router.push({
					path: '/support',
				})
			},
			go_product() {
				this.$router.push({
					path: '/product',
				})
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
				})
			},
		},
	}
</script>
<style scoped lang="less">
	.page_bottom {
		width: 100%;
		min-width: 1380px;
		// height: 10vh;
		background-color: #323232;
	
		.page_bottom_top {
			width: 1360px;
			height: 342px;
			margin: 0 auto;
			background-color: #323232;
			display: flex;
			justify-content: space-between;
			align-items: center;
	
	
			.bot-left {
				width: 50%;
	
				.bot-left-top {
					display: flex;
	
					.bot-left-top-left {
						display: flex;
						justify-content: flex-start;
						width: 50%;
	
						.bot-img {
							cursor: pointer;
							width: 56px;
							height: 56px;
							margin-right: 25px;
						}
					}
	
					.bot-left-top-right {
						width: 50%;
						display: flex;
						justify-content: flex-end;
	
						.bot-img {
							cursor: pointer;
							width: 56px;
							height: 56px;
							margin-left: 25px;
						}
					}
				}
	
				.bot-left-line {
					width: 100%;
					height: 1px;
					background-color: #FFFFFF;
					opacity: 0.5;
					border: 1px solid #FFFFFF;
					margin: 30px 0 30px 0;
				}
	
				.bot-left-bottom {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 18px;
					line-height: 22px;
					color: #ffffff;
	
					.bottom-spa {
						cursor: pointer;
					}
	
					.bottom-spa:hover {
						font-size: 20px;
					}
	
				}
	
	
			}
	
			.bot-right {
				width: 45%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: 21px;
				color: #DEDEDE;
	
				.bot-right-item {
					margin-bottom: 30px;
					display: flex;
	
					.left-icon {
						width: 33px;
						height: 33px;
						margin-right: 16px;
	
						.left-icon-img {
							width: 33px;
							height: 33px;
						}
					}
	
					.right-content {
						flex: 1;
					}
				}
			}
	
		}
	
		.page_bottom_center {
			display: flex;
			justify-content: center;
			align-items: center;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			width: 100%;
			height: 78px;
			line-height: 78px;
			text-align: center;
			font-size: 12px;
			color: #8A8A8A;
			background-color: #1E1E1E;
		}
	}
</style>
