<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />

			<div class="page_1_loop">
				<div id="mySwipers"   class="banner1 swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" @click="go_img(item)" v-for="(item,index) in carousel_list"
							:key="index"><img class="banner1-img" :src="item.background" alt="" /></div>
						<!-- <div class="swiper-slide"><img class="banner1-img" src="../../assets/images/loop_img_1.png"
								alt="" /></div>
						<div class="swiper-slide"><img class="banner1-img" src="../../assets/images/loop_img_1.png"
								alt="" /></div>
						<div class="swiper-slide"><img class="banner1-img" src="../../assets/images/loop_img_1.png"
								alt="" /></div>
						<div class="swiper-slide"><img class="banner1-img" src="../../assets/images/loop_img_1.png"
								alt="" /></div>
						<div class="swiper-slide"><img class="banner1-img" src="../../assets/images/loop_img_1.png"
								alt="" /></div>
						<div class="swiper-slide"><img class="banner1-img" src="../../assets/images/box-1.png" alt="" />
						</div> -->
					</div>
					<div class="swiper-pagination"></div>
					<div class="bann1-left  swiper-button-prev"></div>
					<div class="bann1-right swiper-button-next"></div>
				</div>
			</div>
		</div>
		<div class="page_2">
			<div class="page_2_1">
				<div class="page_2_1_top">
					<div class="top-title">
						OUR SOLUTIONS
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_2_1_bot">
					<div class="page_2_1_bot_left">
						<div class="left-list">
							<div class="left-item" v-for="(item,index) in solution_list" :key="index"
								@mouseenter="enter_solut(item)" @click="go_soluts_dtail"
								:style="show_solut_id === item.id ? 'color: #0081CC' : ''">
								<div class="item-left">
									{{ item.name }}
								</div>
								<div class="item-right">
									<img class="item-right-img" v-show="show_solut_id === item.id"
										src="../../assets/images/next_1.png" alt="" />
								</div>
							</div>
						</div>
						<div class="page_2_1_bot_left_btn" @click="go_soluts">
							<span>LEARN MORE</span>
							<img class="bott-img" src="../../assets/images/next_2.png" alt="" />
						</div>
					</div>
					<div class="page_2_1_bot_right">
						<div class="page_2_img" :style="{backgroundImage:'url('+show_solut_detail.img+')'}">
							<div class="page_2_box">
								<div class="line-title">
									{{ show_solut_detail.name }}
								</div>
								<div class="line-bot">
									{{ show_solut_detail.msg }}
								</div>
								<div class="bot-btn" @click="go_soluts_dtail">

								</div>
							</div>
						</div>
					</div>
				</div>


				<!-- <SwiperDemo :show_num="4" :show_flag="true"/> -->
			</div>
		</div>
		<div class="page_3">
			<div class="page_3_1">
				<div class="page_3_1_top">
					<div class="top-title">
						OUR PRODUCTS
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_3_1_cen">
					<div class="cen-list">
						<div class="cen-item" v-for="(item,index) in device_list" @click="change_banner2(item)"
							:key="index">
							{{ item.name }}
						</div>
					</div>
				</div>
				<div class="page_3_1_bot">
					<!-- 放在外面 -->
					<!-- <div class="swiper-button-prev"></div> -->
					<div class="btn-left swiper-button-prev" v-show="slideList.length > 4">
						<img src="../../assets/images/swiper_left.png" alt="" />
					</div>
					<div id="mySwiper"   class="banner2 swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide my-swiper-slide" v-for="(item,index) of slideList" :key="item.id">
								<div class="box">
									<div class="box_img" @mouseenter="show_device(item)"
										@mouseleave="leave_device(item)">
										<img :src="item.img" v-show="!item.show_device_detail" class="imgs"
											:path="item.path" :id="item.id" :name="item.msg_1">
										<div class="img-detail" v-show="item.show_device_detail">
											<div class="device-title">
												{{ device_detail.name }}
											</div>
											<div class="device-type">
												{{ device_detail.type }}
											</div>
											<div class="device-content">
												{{ device_detail.intro }}
											</div>
											<div style="width: 80%;margin: 0 auto;">
												<div class="device-btn" @click="go_device_detail">
													<span>READ MORE</span>
													<img class="bott-img" src="../../assets/images/next_3.png" alt="" />
												</div>
											</div>

										</div>
									</div>
								</div>

							</div>

						</div>
					</div>
					<!-- 放在外面 -->
					<!-- <div class="swiper-button-next"></div> -->
					<div class="btn-right swiper-button-next" v-show="slideList.length > 4">
						<img src="../../assets/images/swiper_right.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="page_4">
			<div class="page_4_1">
				<div class="page_4_1_top">
					<div class="top-title">
						ABOUT US
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_4_box_1">
					<div class="page_4_box_1_left">
						<div class="video-class" id="dplayer">

						</div>
					</div>
					<div class="page_4_box_1_right">
						<div class="video-title">
							SZZT ELECTRONICS CO., LTD.
						</div>
						<div class="video-content">
							SZZT is a professional fintech solution provider started from 1993, focusing on retail and
							banking industry, providing comprehensive solutions covering payment terminals, self-service
							terminals and platform services, helping to build an efficient and convenient financial
							service network, and leading to a smarter future.
						</div>
						<div style="width: 94%;margin: 0 auto;">
							<div class="more-btn" @click="go_about">
								<span>READ MORE</span>
								<img class="bott-img" src="../../assets/images/next_3.png" alt="" />
							</div>
						</div>

					</div>
				</div>
				<div class="page_4_box_2">
					<div class="page_4_box_2_item">
						<div class="item-num">
							<img src="../../assets/images/1993.png" alt="" />
						</div>
						<div class="item-con">
							Founded in
						</div>
					</div>
					<div style="width: 1px;height: 50px;background-color: #C8C8C8;">

					</div>
					<div class="page_4_box_2_item">
						<div class="item-num">
							<img src="../../assets/images/6bill.png" alt="" />
						</div>
						<div class="item-con">
							Total assets
						</div>
					</div>
					<div style="width: 1px;height: 50px;background-color: #C8C8C8;">

					</div>
					<div class="page_4_box_2_item">
						<div class="item-num">
							<img src="../../assets/images/10m.png" alt="" />
						</div>
						<div class="item-con">
							Total shipments
						</div>
					</div>
					<div style="width: 1px;height: 50px;background-color: #C8C8C8;">

					</div>
					<div class="page_4_box_2_item" style="border: 0;">
						<div class="item-num">
							<img src="../../assets/images/160+.png" alt="" />
						</div>
						<div class="item-con">
							Countries
						</div>
					</div>
				</div>

				<div class="page_4_box_3">

				</div>

			</div>
		</div>
		<div class="page_5" v-loading="news_loading">
			<div class="page_5_1">
				<div class="page_5_1_top">
					<div class="top-title">
						LATEST NEWS
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_5_1_bot">
					<div class="page_5_1_bot_left">
						<div class="page_5_img">

						</div>
						<div class="page_5_box">
							<div class="date-box">
								<div class="date-day">
									{{ news_detail.date }}
								</div>
								<div class="date-mon">
									{{ news_detail.month }}
								</div>
							</div>
							<div class="cen-box">
								<div class="title">
									{{ news_detail.headline }}
								</div>
								<div class="conten">
									{{ news_detail.summary }}
								</div>
							</div>
							<div class="rig-btn" @click="go_news_detail(news_detail.id)">

							</div>
						</div>
					</div>
					<div class="page_5_1_bot_right">
						<div class="news-list">
							<div class="news-item" @click="go_news_detail(item.id)" v-for="(item,index) in news_list"
								:key="index">
								<div class="news-left">
									<div class="date-day">
										{{ item.date }}
									</div>
									<div class="date-mon">
										{{ item.month }}
									</div>
								</div>
								<div class="news-cen">
									<div class="title">
										{{ item.headline }}
									</div>
									<div class="conten">
										{{ item.summary }}
									</div>
								</div>
								<div class="news-right">
									<img class="rig-images" src="../../assets/images/next_4.png" alt="" />
								</div>
							</div>
							<div class="news-item" v-if="news_list.length == 0" style="opacity: 0;">

							</div>
							<div class="pagin-class">
								<div class="pagin-box">
									<div class="pagin-left" @click="back_page">
										<img class="pagin-left-img" :style="dis_back ? 'opacity: .35' : ''"
											src="../../assets/images/flag_1.png" />
									</div>
									<div style="width: 2px; height: 37px;background-color: #F0F0F0;">

									</div>
									<div class="pagin-right" @click="next_page">
										<img class="pagin-left-img" :style="dis_next ? 'opacity: .35' : '' "
											src="../../assets/images/flag_2.png" />
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>


			</div>
		</div>
		<div class="page_share">
			<div class="share-title">
				Want to know more?
			</div>
			<div class="share-con">
				Any questions or request, we will reply you within 24 hours.
			</div>
			<div class="share-button" @click="go_contact()">
				<span>Contact US</span>
				<img class="bott-img" src="../../assets/images/next_3.png" alt="" />
			</div>
		</div>
		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	// import SwiperDemo from "@/components/BaseSwiper.vue"
	import PageBot from "@/components/PageBottom.vue"
	import PageNav from "@/components/PageNav.vue"
	import DPlayer from 'dplayer';
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	import {
		getTreeApi,
		getDeviceList,
		getCarouselApi,
		getDeviceDetail,
		getVideo,
		getTreesApi,
		getNews,
		getNewsDetail
	} from '@/api/index';
	export default {
		name: 'index',
		components: {
			PageBot,
			PageNav
			// SwiperDemo
		},
		created() {
			localStorage.setItem("load_refresh", "true"); // 存入缓存
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
			this.get_news_list()
			this.init_data()
		},
		destroyed() {
			this.destroySwiper()
		},

		watch: {
			//slide数据发生变化时,更新swiper
			// slideList: {
			// 	deep: true,
			// 	// eslint-disable-next-line
			// 	handler(nv, ov) {
			// 		console.log("数据更新了1")
			// 		this.updateSwiper()
			// 	}
			// },
			ban_show_num: {
				deep: true,
				// eslint-disable-next-line
				handler(nv, ov) {
					this.updateSwiper()
				}
			}
		},

		mounted() {
			var that = this;
			setInterval(function() {
				that.flag_status = !that.flag_status; // 切换图标状态，上下浮动
				// console.log(that.flag_status)
			}, 1000)


			window.addEventListener("scroll", this.watchScroll); // 滚动吸顶


			this.screenWidth = document.body.clientWidth
			window.onresize = () => {
				return (() => {
					this.screenWidth = document.body.clientWidth // 监听屏幕宽度
				})()
			}
			var _this = this;
			setTimeout(function() {
				_this.init_loop()
				_this.initSwiper()
				_this.init_video()

			}, 300)

		},
		data() {
			return {
				acctive_cata_id: 0,
				acctive_type_id: 10,
				show_top: false,
				avtive_nav: 0,
				ban_show_num: 4,
				show_nav: true,
				show_device_detail: false,
				device_detail: {},
				currentSwiper: null,
				currentSwipers: null,
				select_form: {
					pageNum: 1,
					pageSize: 4
				},
				total: 0,
				dis_back: true,
				dis_next: false,
				show_solut_id: 0,
				show_solut_detail: {
					id: 0,
					name: "Mobile Payment",
					msg: "With the development of mobile internet technology and the popularization of smartphones, the convenient, fast, and secure mobile payment becoming common.We provide an overall mobile payment solution to help our partner start their own mobile payment business.",
					img: require('../../assets/images/solut_0.png'),
					active: true
				},
				solution_list: [{
						id: 0,
						name: "Mobile Payment",
						msg: "With the development of mobile internet technology and the popularization of smartphones, the convenient, fast, and secure mobile payment becoming common.We provide an overall mobile payment solution to help our partner start their own mobile payment business.",
						img: require('../../assets/images/solut_0.png'),
						active: true
					},
					{
						id: 1,
						name: "Smart Hospital",
						msg: "Through online service portal and Kiosks, to provide overall patient services covering pre-visit, during-visit and post-visit. Greatly reduce queuing time, simplify the payment and inquiry transactions, and provide personalized patient care, thus improve efficiency and patient satisfaction.",
						img: require('../../assets/images/solut_1.png'),
						active: false
					},
					{
						id: 2,
						name: "Smart Banking / Branch Transformation",
						msg: "Through online service portal and Kiosks,to proviThe smart branch solution provides a customer facing self-service protal.",
						img: require('../../assets/images/solut_2.png'),
						active: false
					},
					{
						id: 3,
						name: "Digital Taxation",
						msg: "Digital Taxation Solution Including Tax Control System(TCS)，Integrated Tax Administration System(ITAS)，e-Invoice System， Fiscal Registered Cashier(FRC)，and Tax Service Kiosk.By establishing a tax control system based on invoices, transaction information can be digitalized and collected in real-time, to improve tax certainty and efficiency, also reduce the work burden of taxpayers.",
						img: require('../../assets/images/solut_4.png'),
						active: false
					}
				],
				device_list: [],
				news_loading: false,
				news_list: [

				],
				news_detail: {},
				slideList: [{
						id: 0,
						img: require('../../assets/images/device_list_1.png'),
						show_device_detail: false,
					},
					{
						id: 1,
						img: require('../../assets/images/device_list_2.png'),
						show_device_detail: false,
					},
					{
						id: 2,
						img: require('../../assets/images/device_list_3.png'),
						show_device_detail: false,
					},
					{
						id: 3,
						img: require('../../assets/images/device_list_4.png'),
						show_device_detail: false,
					},
					{
						id: 4,
						img: require('../../assets/images/device_list_1.png'),
						show_device_detail: false,
					},
					{
						id: 5,
						img: require('../../assets/images/device_list_1.png'),
						show_device_detail: false,
					}

				],
				carousel_list: [],
				flag_status: true,
				navBarFixed: false,
				screenWidth: null,
				list_status: true,
			}
		},
		methods: {
			// 上一页
			back_page() {
				// 第一页
				if (this.select_form.pageNum == 1) {
					return
				}
				this.select_form.pageNum--
				this.get_news_list()
			},
			// 下一页
			next_page() {
				if (this.dis_next) {
					return
				}
				this.dis_back = false;
				this.select_form.pageNum++
				this.get_news_list()
			},
			// 获取新闻列表
			get_news_list() {
				this.news_loading = true;
				if (this.select_form.pageNum == 1) {
					this.dis_back = true;
				}
				getNews(this.select_form).then(res => {
						this.news_detail = res.data.rows[0]
						this.news_list = res.data.rows;
						this.total = res.data.total;
						if (this.select_form.pageNum * this.select_form.pageSize >= this.total) {
							this.dis_next = true;
						} else {
							this.dis_next = false;
						}
						for (var i = 0; i < this.news_list.length; i++) {
							let time = this.news_list[i].publishTime
							let date = this.timeTrans(time).split(" ")[0].split("-")[2]
							let month = new Date(time).toDateString().split(" ")[1]
							this.news_list[i]['date'] = date
							this.news_list[i]['month'] = month
						}
						this.news_list.shift()
					})
					.finally(() => {
						setTimeout(() => {
							this.news_loading = false;
						}, 500)
					})
			},
			// 时间转换
			timeTrans(time, type) {
				let date = new Date(new Date(time).getTime() + 8 * 3600 * 1000);
				date = date.toJSON();
				if (type === 1) {
					date = date.substring(0, 10);
				} else {
					date = date ? date.substring(0, 19).replace("T", " ") : "";
				}
				return date;
			},
			// 新闻详情
			go_news_detail(id) {
				this.$router.push({
					path: '/news',
					query: {
						id: id // 携带参数
					},
				})
				// getNewsDetail({
				// 	id:id
				// }).then(res=>{
				// 	console.log(res)
				// })
			},
			// 鼠标移入-解决方案
			enter_solut(item) {
				this.show_solut_id = item.id;
				this.show_solut_detail = item
				for (var i = 0; i < this.solution_list.length; i++) {
					if (item.id == this.solution_list[i].id) {
						this.solution_list[i].active = true
					} else {
						this.solution_list[i].active = false
					}
				}
			},
			go_img(item) {
				console.log(item)
				if (item.reference) {
					// 跳转站外
					if (item.externalLink) {
						window.open(item.reference)
					} else {
						// 跳转站内
						if (item.reference[0] == "Products") {
							// 产品
							this.$router.push({
								path: '/' + item.reference[1],
							})
						} else if (item.reference[0] == "Solutions") {
							// 解决方案
							switch (item.reference[1]) {
								case "Mobile Payment Solution":
									this.$router.push({
										path: '/solut-' + 0,
									})
									break;
								case "Smart Hospital Solution":
									this.$router.push({
										path: '/solut-' + 1,
									})
									break;
								case "Smart Branch Solution":
									this.$router.push({
										path: '/solut-' + 2,
									})
									break;
								case "Digital Taxation":
									this.$router.push({
										path: '/solut-' + 3,
									})
									break;

								default:
							}
						}
					}

				}
			},
			init_video() {
				// getVideo().then(res=>{
				// 	console.log(res)
				// })
				const dp = new DPlayer({
					container: document.getElementById('dplayer'),
					lang: "en", // 可选值：'en'、'zh-cn'、'zh-tw'
					loop: false, // 循环
					autoplay: false, // 自动播放
					live: false, // 直播形式
					video: {
						// url: require('../../assets/video/move.mp4'),
						url: this.api_base_url + "/offical-img/video/sample.mp4",
						// url:  this.api_base_url + "/dev-platform-api/api/ows/media/video/play/sample.mp4",
						pic: require('../../assets/video/pic.jpg'),
					},
				});
			},


			init_loop() {
				getCarouselApi().then(res => {
					this.carousel_list = res.data
					// eslint-disable-next-line
					var vueComponent = this //获取vue组件实例
					this.$nextTick(() => {
						vueComponent.currentSwipers = new Swiper('.banner1', {
							loop: true, // 循环模式选项
							slidesPerView: 1, // 显示个数
							pagination: {
								el: '.swiper-pagination',
								clickable: true, //分页器按钮可点击
							},
							paginationClickable: true,
							//导航器
							navigation: {
								nextEl: '.bann1-right',
								prevEl: '.bann1-left',
							},
							on: {
								click: function(event) {
									// console.log(event.target)
									// let img_id = event.target.getAttribute('id');
									// let img_name = event.target.getAttribute('name');
									// if (img_id != null) {
									// 	console.log("点击了-" + (Number(img_id) + 1) + "-号图片")
									// 	that.$emit("show_img_name",img_name)
									// }
								}
							},

						})
					})
				})





			},
			init_data() {
				let res = [{
						"id": "176344396874430228737",
						"name": "HOT",
						"weight": 0,
						"children": [{
								"id": "1763458975557173250",
								"name": "i90",
								"type": "Classic POS",
								"description": null,
								"intro": "Handy size, enhanced communication and battery life, to support easy card payment & mobile payment anywhere.",
								"img": require('../../assets/images/products/I90.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1763463658535272449",
								"name": "KS8226",
								"type": "Smart POS",
								"description": null,
								"intro": "A powerful portable all-in-one payment terminal equipped with latest Android system.",
								"img": require('../../assets/images/products/KS8226.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1769634619781541890",
								"name": "ZT8648",
								"type": "Desktop ECR",
								"description": null,
								"intro": "Dual 15.6-inch LCD display, 2.0GHz Quad core processor, rich peripheral interfaces, to support your business with plenty flexibility.",
								"img": require('../../assets/images/products/ZT8648.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770346946275844097",
								"name": "ZT2214",
								"type": "STM",
								"description": null,
								"intro": "Smart Teller Machine can integrate multiple peripherals into 1 self-service device, supporting 95% non-cash business such as card issuance, inquiry, transfer, printing, and etc.",
								"img": require('../../assets/images/products/ZT2214.png'),
								"weight": 0,
								show_device_detail: false
							},
						]
					},
					{
						"id": "1763443968744308737",
						"name": "POS",
						"weight": 0,
						"children": [{
								"id": "1763463658535272449",
								"name": "KS8226",
								"type": "Smart POS",
								"description": null,
								"intro": "A powerful portable all-in-one payment terminal equipped with latest Android system.",
								"img": require('../../assets/images/products/KS8226.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1769634300863442946",
								"name": "KS8223",
								"type": "Smart POS",
								"description": null,
								"intro": "5.5” large screen all-in-one payment terminal equipped with latest Android system.",
								"img": require('../../assets/images/products/KS8223.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1763458975557173250",
								"name": "i90",
								"type": "Classic POS",
								"intro": "Handy size, enhanced communication and battery life, to support easy card payment & mobile payment anywhere.",
								"img": require('../../assets/images/products/I90.png'),
								"weight": 0,
								show_device_detail: false
							}
						]
					},
					{
						"id": "1769611078691016706",
						"name": "ECR",
						"weight": 0,
						"children": [{
								"id": "1769634619781541890",
								"name": "ZT8648",
								"type": "Desktop ECR",
								"description": null,
								"intro": "Dual 15.6-inch LCD display, 2.0GHz Quad core processor, rich peripheral interfaces, to support your business with plenty flexibility.",
								"img": require('../../assets/images/products/ZT8648.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "8648646",
								"name": "ZTS-HR22",
								"type": "Accessaries",
								"description": null,
								"intro": "Plug & Play scanner to read 1D / 2D barcode",
								"img": require('../../assets/images/products/ZTS-HR22.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1516486",
								"name": "ZTS-FR20",
								"type": "Accessaries",
								"description": null,
								"intro": "Plug & Play scanner to read 1D / 2D barcode",
								"img": require('../../assets/images/products/ZTS-FR20.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "386446486",
								"name": "ZTS-CPS70",
								"type": "Accessaries",
								"description": null,
								"intro": "Wireless communication, voice announcement, fast printing, support cloud service..",
								"img": require('../../assets/images/products/ZTS-CPS70.png'),
								"weight": 0,
								show_device_detail: false
							}
						]
					},
					{
						"id": "1763444826240401410",
						"name": "QR Terminals",
						"weight": 0,
						"children": [{
								"id": "1770347105424515074",
								"name": "ZT8303",
								"type": "QR Speaker",
								"description": null,
								"intro": "Customer scan to pay, support various customization, clear income announcement and wireless communication to adapt different environment.",
								"img": require('../../assets/images/products/KS8303.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770347165465976833",
								"name": "ZT8309",
								"type": "QR Speaker",
								"description": null,
								"intro": "Dynamic QR code with amount display, to provide better experience, support various customization.",
								"img": require('../../assets/images/products/ZT8309.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1769635081465360386",
								"name": "ZT260",
								"type": "QR POS",
								"description": null,
								"intro": "A light QR POS with wireless communication, amount input and display, income announcement.",
								"img": require('../../assets/images/products/ZT260.png'),
								"weight": 0,
								show_device_detail: false
							},

							{
								"id": "8648646",
								"name": "ZTS-HR22",
								"type": "QR Reader",
								"description": null,
								"intro": "Plug & Play scanner to read 1D / 2D barcode",
								"img": require('../../assets/images/products/ZTS-HR22.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1516486",
								"name": "ZTS-FR20",
								"type": "QR Reader",
								"description": null,
								"intro": "Plug & Play scanner to read 1D / 2D barcode",
								"img": require('../../assets/images/products/ZTS-FR20.png'),
								"weight": 0,
								show_device_detail: false
							},
						]
					},
					{
						"id": "1763444904267038721",
						"name": "Kiosk",
						"weight": 0,
						"children": [{
								"id": "177035022031515645",
								"name": "ZT2220",
								"type": "Freestanding",
								"description": null,
								"intro": " HD screen brings you super visual enjoyment. Integrated with various peripherals to support overall self-services. To digitalize the workflow, reduce waiting time, improve satisfaction and efficiency.",
								"img": require('../../assets/images/products/ZT2220.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "17703502241515645",
								"name": "ZT2880",
								"type": "Freestanding",
								"description": null,
								"intro": "Slim and smart design, simplified operation, fast printing, to deliver an exceptionally smooth printing experience.",
								"img": require('../../assets/images/products/ZT2880.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1568235476878411",
								"name": "ZT2618",
								"type": "Freestanding",
								"description": null,
								"intro": "Multifunction Kiosk, the double screen design enables diverse usage scenarios: broadcasting advertisements or instructional videos on the upper screen, while all business operations are conducted on the main screen.",
								"img": require('../../assets/images/products/ZT2618.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1769635277591015425",
								"name": "ZT8669",
								"type": "Desktop",
								"description": null,
								"intro": "Multi-function Kiosk, payment & self-service, recommended for retail, hospitality, government services. ",
								"img": require('../../assets/images/products/ZT8669.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770349934927159298",
								"name": "KS8123Plus",
								"type": "Desktop",
								"description": null,
								"intro": "Multi-function Kiosk, payment & self-service, recommended for retail, hospitality, government services. ",
								"img": require('../../assets/images/products/KS8123Plus.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770346671842533377",
								"name": "ZT8669Plus",
								"type": "Wall-mounted",
								"description": null,
								"intro": "Multi-function Kiosk, wall mounted type, payment & self-service, recommended for retail, hospitality, government services. ",
								"img": require('../../assets/images/products/ZT8669Plus.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770346946275844097",
								"name": "ZT2214",
								"type": "STM",
								"description": null,
								"intro": "Smart Teller Machine can integrate multiple peripherals into 1 self-service device, supporting 95% non-cash business such as card issuance, inquiry, transfer, printing, and etc.",
								"img": require('../../assets/images/products/ZT2214.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770350153702055937",
								"name": "ZT6240",
								"type": "ATM / VTM",
								"description": null,
								"intro": "Multifunction cash dispenser to support cash withdrawal, transfer, inquiry and etc.",
								"img": require('../../assets/images/products/ZT6240.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770350220315992066",
								"name": "ZT6250",
								"type": "ATM / VTM",
								"description": null,
								"intro": "Multi-function cash recycling system to support cash deposit and withdrawal, transfer, inquiry and etc.",
								"img": require('../../assets/images/products/ZT6250.png'),
								"weight": 0,
								show_device_detail: false
							},

						]
					},
					{
						"id": "1763444946105221122",
						"name": "Encrypting PIN Pad",
						"weight": 0,
						"children": [{
								"id": "1770345997096460289",
								"name": "ZT588F",
								"type": "PCI",
								"description": null,
								"intro": "Various EPP to fit your self-payment and ATM terminals.",
								"img": require('../../assets/images/products/ZT588F.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770346087148167169",
								"name": "ZT598L",
								"type": "PCI",
								"description": null,
								"intro": "Various EPP to fit your self-payment and ATM terminals.",
								"img": require('../../assets/images/products/ZT598L.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770346167443922946",
								"name": "ZT598M",
								"type": "PCI",
								"description": null,
								"intro": "Various EPP to fit your self-payment and ATM terminals.",
								"img": require('../../assets/images/products/ZT598M.png'),
								"weight": 0,
								show_device_detail: false
							},
							// {
							// 	"id": "1770346246070345730",
							// 	"name": "ZT598H",
							// 	"type": "PCI",
							// 	"description": null,
							// "intro": "Various EPP to fit your self-payment and ATM terminals.",
							// 	"img": require('../../assets/images/products/ZT598H.png'),
							// 	"weight": 0,
							// 	show_device_detail: false
							// },
							{
								"id": "1770346312671698946",
								"name": "ZT598B",
								"type": "PCI",
								"description": null,
								"intro": "Various EPP to fit your self-payment and ATM terminals.",
								"img": require('../../assets/images/products/ZT598B.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770346400227794945",
								"name": "ZT599NM",
								"type": "non-PCI",
								"description": null,
								"intro": "Various EPP to fit your self-payment and ATM terminals.",
								"img": require('../../assets/images/products/ZT599NM.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770346473745555457",
								"name": "ZT599N",
								"type": "non-PCI",
								"description": null,
								"intro": "Various EPP to fit your self-payment and ATM terminals.",
								"img": require('../../assets/images/products/ZT599N.png'),
								"weight": 0,
								show_device_detail: false
							}
						]
					},
					{
						"id": "1763444865004158978",
						"name": "PDA",
						"weight": 0,
						"children": [{
								"id": "1769635591522086914",
								"name": "ZT8211",
								"type": "Industrial PDA",
								"description": null,
								"intro": "IP65 protection, 1.5m drop(6 sides). Fast scanning, OCR engine to support rich text reading.Large battery, support fast charging.",
								"img": require('../../assets/images/products/ZT8211.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1769635705569406978",
								"name": "ZT8212",
								"type": "Industrial PDA",
								"description": null,
								"intro": "IP65 protection, 1.5m drop(6 sides). Fast scanning, OCR engine to support rich text reading.Large battery, support fast charging.",
								"img": require('../../assets/images/products/ZT8212.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1769635838264602625",
								"name": "ZT8213",
								"type": "Industrial PDA",
								"description": null,
								"intro": "IP65 protection, 1.5m drop(6 sides). Fast scanning, OCR engine to support rich text reading.Large battery, support fast charging.",
								"img": require('../../assets/images/products/ZT8213.png'),
								"weight": 0,
								show_device_detail: false
							}
						]
					},
					{
						"id": "1763444768031850498",
						"name": "Fiscal Devices",
						"weight": 0,
						"children": [{
								"id": "1770345684998299650",
								"name": "ZT8350",
								"type": "Fiscal Device",
								"description": null,
								"intro": "Fiscal Registered Cashier, to digitalize the tax data and realize real-time tax calculation. Financial encryption, support various algorithm. Dismantling monitoring mechanism, black box technique to ensure no data loss.",
								"img": require('../../assets/images/products/ZT8350.png'),
								"weight": 0,
								show_device_detail: false
							},
							{
								"id": "1770345777004552194",
								"name": "ZT8351",
								"type": "Fiscal Device",
								"description": null,
								"intro": "Work with existing cashier to realize rapid digital taxation transformation",
								"img": require('../../assets/images/products/ZT8351.png'),
								"weight": 0,
								show_device_detail: false
							}
						]
					},

				]
				this.device_list = res;
				// this.device_list = res.data
				this.slideList = this.device_list[0].children
				// for (var i = 0; i < this.slideList.length; i++) {
				// 	this.slideList[i]['show_device_detail'] = false
				// }
			},
			change_banner2(item) {
				this.slideList = item.children
				this.ban_show_num = 0; // 用于触发witch
				// this.ban_show_num = item.children.length
				if (item.children.length < 4) {
					this.ban_show_num = item.children.length
				} else {
					this.ban_show_num = 4
				}
				console.log(this.ban_show_num)
			},
			//更新swiper
			updateSwiper() {
				try {
					this.currentSwiper.destroy(true, false)
				} catch (e) {
					console.log("删除轮播")
				}
				var that = this;
				this.$nextTick(() => {
					that.initSwiper()
				})
			},
			//初始化swiper
			initSwiper() {
				// getTreesApi().then(res=>{
				// 	console.log(res)
				// 	this.device_list = res.data
				// 	this.slideList = this.device_list[0].products
				// 	for (var i = 0; i < this.slideList.length; i++) {
				// 		this.slideList[i]['show_device_detail'] = true;
				// 	}
				// 	console.log(this.slideList)
				// 	var that = this
				// })
				this.currentSwiper = new Swiper('.banner2', {
					loop: false, // 循环模式选项
					autoplay: false, // 自动循环
					// autoplay: {
					// 	reverseDirection: true,
					// },
					slidesPerView: this.ban_show_num, // 显示个数
					// autoHeight: 'true', //开启自适应高度,容器高度由slide高度决定
					//导航器
					navigation: {
						nextEl: '.btn-right',
						prevEl: '.btn-left',
					},

				})

			},
			//销毁swiper
			destroySwiper() {
				try {
					this.currentSwiper.destroy(true, false)
					this.currentSwipers.destroy(true, false)
				} catch (e) {
					console.log("删除轮播")
				}
			},
			// 鼠标移入-设备
			show_device(item) {
				item.show_device_detail = true
				// console.log(item.show_device_detail)
				this.device_detail = item
			},
			// 鼠标移入-设备
			leave_device(item) {
				item.show_device_detail = false
				this.device_detail = {}
			},
			go_device_detail() {
				this.$router.push({
					path: '/' + this.device_detail.name,
				})
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
					query: {
						id: '123456'
					},

				})
			},
			go_beian() {
				// window.open("https://beian.miit.gov.cn/")
			},

			// 滚动吸顶
			watchScroll() {
				// 当滚动超过 90 时，实现吸顶效果
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 90) {
					this.navBarFixed = true;
					if (scrollTop > 660) {
						this.navBarFixed = false;
						this.show_nav = false;
					}
				} else {
					this.show_nav = true;
					if (this.show_top) {
						return
					}
					this.navBarFixed = false;
				}
			},
			// // 翻页函数--箭头
			// next_page() {
			// 	var el = document.getElementsByClassName(`page_2`)[0]; // 下滑一页
			// 	// var el = document.getElementsByClassName(`page_1`)[0];  // 回到顶部
			// 	// console.log(el.offsetTop);
			// 	this.$nextTick(function() {
			// 		window.scrollTo({
			// 			"behavior": "smooth", // 平滑过渡
			// 			"top": el.offsetTop
			// 		});
			// 	})
			// },
			go_about() {
				this.$router.push({
					path: '/about',
					query: {
						id: '123456'
					},

				})
			},
			go_soluts() {
				this.$router.push({
					path: '/solutions',
				})
			},
			go_soluts_dtail() {
				this.$router.push({
					path: '/solut-' + this.show_solut_detail.id,
				})
			},
			go_detail(key_word) {

				switch (key_word) {
					case 'Tencent_map':
						console.log(key_word)
						this.$router.push({
							path: '/' + key_word,
							query: {
								id: '123456'
							},

						})
						break;
					case 'swiper_carousel':
						console.log(key_word)
						this.$router.push({
							path: '/' + key_word,
							query: {
								id: '123456'
							},

						})
						break;
					case 'graduates_app':
						console.log(key_word)
						break;
					case 'project_deployment':
						console.log(key_word)
						this.$router.push({
							path: '/' + key_word, // 跳转路径
							query: {
								id: '123456' // 携带参数
							},

						})
						break;
					case 'python_spider':
						console.log(key_word)
						break;
					default:
						console.log("---")
						break;
				}
			}
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		user-select: none; // 子元素无法被选中
	}



	.page_1_loop {
		width: 100%;
		height: 100vh;
		max-height: 848px;
		overflow: hidden;
		// background-color: aqua;
	}

	.page_1_loop:hover {
		.bann1-left {
			display: flex;
		}

		.bann1-right {
			display: flex;
		}
	}


	.banner1 {
		.bann1-left {
			display: none;
			margin-left: 3%;
			width: 63px;
			height: 63px;
			border-radius: 50%;
			background: rgba(0, 0, 0, 0.2);
			color: #ffffff;
		}


		.bann1-right {
			display: none;
			width: 63px;
			height: 63px;
			border-radius: 50%;
			background: rgba(0, 0, 0, 0.2);
			color: #ffffff;
			margin-right: 3%;
		}

		.bann1-left:hover {
			background-color: aqua;
		}

		.bann1-right:hover {
			background-color: aqua;
		}

		/deep/ .swiper-button-next:after,
		.swiper-button-prev:after {
			font-size: 20px;
			font-weight: 700;
		}

		/deep/ .swiper-pagination-bullet {
			width: 13px;
			height: 13px;
			text-align: center;
			line-height: 20px;
			font-size: 18px;
			color: #000;
			opacity: 1;
			background: rgba(0, 0, 0, 0.2);
			margin: 0 10px 10px 10px;
		}

		/deep/ .swiper-pagination-bullet-active {
			color: #fff;
			// background: #ff51d6;
			background: #ffffff;
			border: 4px solid rgba(0, 0, 0, 0.15);
		}
	}

	.swiper-pagination {
		text-align: center;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.navBarWrap {
		width: 100%;
		background-color: #ffffff;
		position: fixed;
		overflow: hidden;
		top: 0;
		z-index: 999;
	}



	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}


	.bot-line {
		margin-top: 20px;
		width: 127px;
		height: 4px;
		background-color: #ffffff;
		position: relative;
	}

	.bot-line::before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 100%;
		height: 4px;
		background-color: #0081CC;
		transform-origin: center;
		transform: translate(-50%, 0) scaleX(0);
		transition: transform 0.3s ease-in-out;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}

	.page_2 {
		width: 100%;
		min-width: 1360px;
		background: linear-gradient(180deg, #FCFDFD 0%, #F7F9F9 100%);
	}

	.page_2_1 {
		width: 1360px;
		margin: 0 auto;
		padding-bottom: 80px;


		.page_2_1_top {
			// margin-top: 100px;
			padding-top: 100px;
			width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			.top-title {
				color: #000000;
				font-size: 48px;
			}


		}

		.page_2_1_bot {
			width: 100%;
			height: 600px;
			margin-top: 80px;
			display: flex;

			.page_2_1_bot_left {
				width: 35%;
				height: 100%;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				justify-content: center;

				.left-list {
					width: 420px;

					.left-item {
						transition: all 0.1s; //设置动画执行的时间为0.6s
						padding-top: 40px;
						padding-bottom: 20px;
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						cursor: pointer;
						font-size: 18px;
						// color: #000000;
						border-bottom: 1px solid #DADADA;

						.item-right {
							display: flex;
							justify-content: space-between;
							align-items: center;
							width: 14px;
							height: 15px;

							.item-right-img {
								cursor: pointer;
								width: 14px;
								height: 15px;
							}

						}

					}

					.left-item:hover {
						transition: all 0.1s; //设置动画执行的时间为0.6s

						.item-left {
							color: #0081CC;
						}
					}

					// .left-item:hover{
					// 	color: #0081CC;
					// }


				}

				.page_2_1_bot_left_btn {
					cursor: pointer;
					position: relative;
					z-index: 1;
					width: 197px;
					margin-top: 70px;
					height: 47px;
					background: linear-gradient(316deg, #00B7E8 0%, #0081CC 100%);
					box-shadow: 0px 6px 20px 0px #ABDBF7;
					color: #ffffff;
					font-size: 18px;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;

					.bott-img {
						margin-left: 14px;
						width: 8px;
						height: 8px;
					}

				}

				.page_2_1_bot_left_btn::after {
					content: '';
					z-index: -1;
					background: linear-gradient(316deg, #00B7E8 0%, #0081CC 100%);
					position: absolute;
					top: -50%;
					bottom: -50%;
					width: 197px;
					transform: translate3d(-525%, 0, 0) rotate(300deg);
				}

				.page_2_1_bot_left_btn:hover::after {
					transition: transform 0.9s ease-in-out;
					transform: translate3d(200%, 0, 0) rotate(300deg);
				}

			}

			.page_2_1_bot_right {
				width: 65%;
				height: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;


				.page_2_img {
					width: 770px;
					height: 580px;
					// background-image: url("../../assets/images/box-1.png");
					background-size: cover;
					object-fit: cover; // 保持图片缩放时候不变形
					display: flex;
					align-items: flex-end;

					.page_2_box {
						margin-left: -100px;
						margin-bottom: 40px;
						width: 680px;
						height: 180px;
						opacity: 0.84;
						color: #FFFFFF;
						background-color: #0D87CF;
						padding: 30px;


						.line-title {
							font-size: 26px;
							font-weight: normal;
						}

						.line-bot {
							height: 78px;
							margin-top: 10px;
							margin-bottom: 10px;
							font-size: 14px;
							font-weight: normal;
							line-height: 26px;
							overflow: hidden;
							text-overflow: ellipsis;
							/* 将对象作为弹性伸缩盒子模型显示 */
							display: -webkit-box;
							/* 限制在一个块元素显示的文本的行数 */
							/* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
							-webkit-line-clamp: 3;
							/* 设置或检索伸缩盒对象的子元素的排列方式 */
							-webkit-box-orient: vertical;
						}

						.bot-btn {
							margin-top: 20px;
							cursor: pointer;
							width: 36px;
							height: 36px;
							background-image: url("../../assets/images/go.png");
						}
					}

				}

			}


		}

	}

	.page_2:hover {
		.bot-line::before {
			transform: translate(-50%, 0) scaleX(1);
		}
	}

	.page_3 {
		width: 100%;
		min-width: 1360px;
		background: linear-gradient(180deg, #FFFFFF 0%, #F7F9F9 100%);
	}

	.page_3 {
		margin: 0 auto;
		padding-bottom: 180px;


		.page_3_1_top {
			padding-top: 100px;
			width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			.top-title {
				color: #000000;
				font-size: 48px;
			}

		}

		.page_3_1_cen {
			margin-top: 50px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.cen-list {
				display: flex;
				color: #000001;
				font-size: 18px;

				.cen-item {
					cursor: pointer;
					padding: 5px 15px 5px 15px;
					margin-left: 15px;
					margin-right: 15px;
				}

				.cen-item:hover {
					background-color: #F6F6F6;
				}
			}

		}

		.page_3_1_bot {
			width: 100%;
			height: 490px;
			margin-top: 80px;
			display: flex;
			justify-content: center;
			position: relative;
			--swiper-navigation-size: 0; // 左右箭头大小

			// .banner2{
			// 	width: 100%;
			// }
		}

	}

	.page_3:hover {
		.bot-line::before {
			transform: translate(-50%, 0) scaleX(1);
		}
	}

	.page_4 {
		width: 100%;
		min-width: 1360px;
		background: linear-gradient(180deg, #FCFDFD 0%, #F7F9F9 100%);
	}

	.page_4_1 {
		width: 1360px;
		margin: 0 auto;
		padding-bottom: 80px;

		.page_4_1_top {
			padding-top: 100px;
			width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			.top-title {
				color: #000000;
				font-size: 48px;
			}

		}


		.page_4_box_1 {
			width: 100%;
			height: 440px;
			margin-top: 80px;
			background-image: url("../../assets/images/box-2.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			display: flex;

			.page_4_box_1_left {
				width: 50%;

				.video-class {
					width: 650px;
					height: 366px;
					margin: 0 auto;
					margin-top: -30px;
					background-color: #ffffff;
				}
			}

			.page_4_box_1_right {
				width: 50%;
				height: 100%;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				font-weight: normal;

				.video-title {
					text-align: left;
					width: 94%;
					margin: 0 auto;
					margin-top: 40px;
					font-size: 40px;
					color: #FFFFFF;
				}


				.video-content {
					text-align: left;
					width: 94%;
					margin: 0 auto;
					margin-top: 20px;
					font-size: 14px;
					line-height: 28px;
					color: #F3F3F3;
				}

				.more-btn {
					margin-top: 40px;
					cursor: pointer;
					width: 197px;
					height: 47px;
					background-color: #FFFFFF;
					color: #0081CC;
					font-size: 18px;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;

					.bott-img {
						margin-left: 14px;
						width: 8px;
						height: 8px;
					}
				}
			}

		}

		.page_4_box_2 {
			width: 100%;
			height: 165px;
			margin-top: 60px;
			display: flex;
			align-items: center;
			background: #FFFFFF;
			box-shadow: 0px 5px 30px 0px rgba(230, 230, 230, 0.5);

			.page_4_box_2_item {
				width: 25%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				// border-right: 1px solid #C8C8C8;

				.item-num {
					height: 70px;

					img {
						height: 70px;
						object-fit: cover; // 保持图片缩放时候不变形
					}
				}

				.item-con {
					font-size: 21px;
					color: #252525;
				}

			}

		}


		.page_4_box_3 {
			width: 100%;
			height: 876px;
			margin-top: 80px;
			background-image: url("../../assets/images/map.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;



		}


	}

	.page_4:hover {
		.bot-line::before {
			transform: translate(-50%, 0) scaleX(1);
		}
	}

	.page_5 {
		width: 100%;
		min-width: 1360px;
		background: linear-gradient(180deg, #FCFDFD 0%, #F7F9F9 100%);
	}

	.page_5_1 {
		width: 1360px;
		margin: 0 auto;
		padding-bottom: 80px;


		.page_5_1_top {
			padding-top: 100px;
			width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			.top-title {
				color: #000000;
				font-size: 48px;
			}

		}

		.page_5_1_bot {
			width: 100%;
			height: 530px;
			margin-top: 80px;
			display: flex;



			.page_5_1_bot_left {
				width: 65%;
				height: 100%;

				.page_5_img {
					width: 770px;
					height: 400px;
					background-image: url("../../assets/images/box-5.png");

				}


				.page_5_box {
					width: 770px;
					height: 130px;
					opacity: 0.84;
					color: #FFFFFF;
					background: linear-gradient(316deg, #00B7E8 0%, #0081CC 100%);
					display: flex;
					align-items: center;

					.date-box {
						width: 98px;
						height: 93px;
						border: 2px solid #FFFFFF;
						margin-left: 26px;
						margin-right: 23px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						.date-day {
							font-size: 60px;
							line-height: 54px;
						}

						.date-mon {
							font-size: 14px;
						}

					}

					.cen-box {
						flex: 1;

						.title {
							line-height: 22px;
							font-size: 24px;
							overflow: hidden;
							text-overflow: ellipsis;
							/* 将对象作为弹性伸缩盒子模型显示 */
							display: -webkit-box;
							/* 限制在一个块元素显示的文本的行数 */
							/* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
							-webkit-line-clamp: 1;
							/* 设置或检索伸缩盒对象的子元素的排列方式 */
							-webkit-box-orient: vertical;
						}

						.conten {
							font-size: 14px;
							line-height: 20px;
							margin-top: 10px;
							overflow: hidden;
							text-overflow: ellipsis;
							/* 将对象作为弹性伸缩盒子模型显示 */
							display: -webkit-box;
							/* 限制在一个块元素显示的文本的行数 */
							/* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
							-webkit-line-clamp: 2;
							/* 设置或检索伸缩盒对象的子元素的排列方式 */
							-webkit-box-orient: vertical;
						}
					}

					.rig-btn {
						cursor: pointer;
						width: 36px;
						height: 36px;
						margin-left: 100px;
						margin-right: 60px;
						background-image: url("../../assets/images/go.png");
					}
				}

			}

			.page_5_1_bot_right {
				width: 35%;
				height: 100%;


				.news-list {
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.news-item {
						width: 100%;
						height: 136px;
						display: flex;
						cursor: pointer;
						background: #FFFFFF;
						box-shadow: 0px 5px 30px 0px rgba(230, 230, 230, 0.5);

						.news-left {
							width: 48px;
							height: 85px;
							color: #ffffff;
							background: linear-gradient(316deg, #00B7E8 0%, #0081CC 100%);
							box-shadow: 0px 6px 20px 0px #ABDBF7;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							margin-right: 30px;

							.date-day {
								font-size: 25px;
								line-height: 24px;
							}

							.date-mon {
								font-size: 12px;
							}
						}

						.news-cen {
							flex: 1;

							.title {
								font-family: HelveticaNeueLTPro-Md;
								margin-top: 30px;
								color: #000000;
								font-size: 16px;
								overflow: hidden;
								text-overflow: ellipsis;
								/* 将对象作为弹性伸缩盒子模型显示 */
								display: -webkit-box;
								/* 限制在一个块元素显示的文本的行数 */
								/* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
								-webkit-line-clamp: 1;
								/* 设置或检索伸缩盒对象的子元素的排列方式 */
								-webkit-box-orient: vertical;
							}

							.conten {
								color: #B7B7B7;
								font-size: 14px;
								line-height: 20px;
								margin-top: 10px;
								overflow: hidden;
								text-overflow: ellipsis;
								/* 将对象作为弹性伸缩盒子模型显示 */
								display: -webkit-box;
								/* 限制在一个块元素显示的文本的行数 */
								/* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
								-webkit-line-clamp: 2;
								/* 设置或检索伸缩盒对象的子元素的排列方式 */
								-webkit-box-orient: vertical;
							}
						}

						.news-right {
							display: flex;
							align-items: flex-end;
							margin-right: 26px;
							margin-bottom: 20px;

							.rig-images {
								cursor: pointer;
								width: 28px;
								height: 28px;
							}

						}

					}

					// .news-item:hover {
					// 	-moz-box-shadow: 2px 3px 8px 8px #DCDCDC;
					// 	-webkit-box-shadow: 2px 3px 8px 8px #DCDCDC;
					// 	box-shadow: 2px 3px 8px 8px #DCDCDC;
					// }

					.pagin-class {
						width: 100%;
						height: 70px;
						display: flex;
						justify-content: flex-end;

						.pagin-box {
							width: 157px;
							height: 70px;
							background: #FFFFFF;
							box-shadow: 0px 5px 30px 0px rgba(213, 213, 213, 0.5);
							display: flex;
							align-items: center;

							.pagin-left,
							.pagin-right {
								width: 50%;
								height: 100%;
								display: flex;
								align-items: center;
								justify-content: center;
								color: #0081CC;
								cursor: pointer;

								.pagin-left-img {
									width: 9px;
									height: 14px;
								}
							}
						}
					}

					// .pagin-box:hover {
					// 	-moz-box-shadow: 2px 3px 8px 8px #DCDCDC;
					// 	-webkit-box-shadow: 2px 3px 8px 8px #DCDCDC;
					// 	box-shadow: 2px 3px 8px 8px #DCDCDC;
					// }

				}

			}
		}


	}

	.page_5:hover {
		.bot-line::before {
			transform: translate(-50%, 0) scaleX(1);
		}
	}

	.page_share {
		margin-top: 80px;
		width: 100%;
		height: 522px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-image: url("../../assets/images/box-6.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		color: #FFFFFF;

		.share-title {
			font-size: 60px;
			margin-bottom: 30px;
		}

		.share-con {
			font-size: 30px;
			margin-bottom: 70px;
		}

		.share-button {
			cursor: pointer;
			width: 197px;
			height: 47px;
			background: #FFFFFF;
			color: #0081CC;
			font-size: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;

			.bott-img {
				margin-left: 14px;
				width: 8px;
				height: 8px;
			}
		}
	}
</style>

<style scoped lang="less">
	// @media only screen and (min-width:945px) and (max-width: 1370px) {}
</style>

<style scoped lang="less">
	// @media only screen and (min-width: 760px) and (max-width:944px) {}
</style>

<style scoped lang="less">
	// @media only screen and (max-width:759px) {}
</style>

<style scoped lang="less">
	* {
		transition: all 1s; // 设置动画效果
	}

	.father {
		display: flex;
		// background-color: aqua; 
		position: relative;
		width: 100%;
		// height: 100%;
		height: 848px;
	}

	.swiper-container {
		height: 100%;
		// background-color: aqua;
	}

	.swiper-slide {
		.banner1-img {
			width: 100%;
			// height: 100vh;
			height: 848px;
			object-fit: cover; // 保持图片缩放时候不变形
			cursor: pointer;
		}
	}

	.box {
		height: 100%;
		width: 90%;
		margin: 0 auto;
		text-align: center;

		.box_img {
			height: 100%;

			.imgs {
				height: 100%;
				width: 100%;
				// width: 409px;
				background-size: cover;
				object-fit: cover; // 保持图片缩放时候不变形
				cursor: pointer;
			}


			.img-detail {
				height: 100%;
				// width: 409px;
				width: 100%;
				margin: 0 auto;
				background-image: url("../../assets/images/device_bg.png");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				font-weight: normal;

				.device-title {
					text-align: left;
					width: 80%;
					margin: 0 auto;
					margin-top: 45px;
					font-size: 36px;
					color: #FFFFFF;
				}

				.device-type {
					text-align: left;
					width: 80%;
					margin: 0 auto;
					margin-top: 12px;
					font-size: 24px;
					color: #FFFFFF;
				}

				.device-content {
					text-align: left;
					width: 80%;
					margin: 0 auto;
					margin-top: 50px;
					font-size: 16px;
					line-height: 27px;
					height: 108px;
					overflow: hidden;
					text-overflow: ellipsis;
					/* 将对象作为弹性伸缩盒子模型显示 */
					display: -webkit-box;
					/* 限制在一个块元素显示的文本的行数 */
					/* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
					-webkit-line-clamp: 4;
					/* 设置或检索伸缩盒对象的子元素的排列方式 */
					-webkit-box-orient: vertical;
					color: #FFFAFA;
				}

				.device-btn {
					margin-top: 32%;
					cursor: pointer;
					width: 197px;
					height: 47px;
					background-color: #FFFFFF;
					color: #0081CC;
					font-size: 18px;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;

					.bott-img {
						margin-left: 14px;
						width: 8px;
						height: 8px;
					}
				}
			}

		}


	}

	.btn-left {
		width: 50px;
		height: 50px;
		background-color: #ffffff;
		border-radius: 50%;
		color: #0081CC;
		margin-top: 320px;
		margin-left: 44%;
	}

	.btn-right {
		width: 50px;
		height: 50px;
		background-color: #0081CC;
		border-radius: 50%;
		color: #ffffff;
		margin-top: 320px;
		margin-right: 44%;
	}

	.btn-left:hover {
		// background-color: #0081CC;
		// color: #ffffff;
	}

	.btn-right:hover {}

	.swiper-button-prev {
		// font-size: 14px;
		// width: 50px;
		// height: 50px;
		// background-color: #ffffff;
		// border-radius: 50%;
		// color: #0081CC;
		// margin-top: 18%;
		// margin-left: 44%;
	}

	.swiper-button-prev:hover {
		// background-color: #0081CC;
		// color: #ffffff;
	}

	.swiper-button-next {
		// font-size: 10px;
		// width: 50px;
		// /* height: 442px; */
		// height: 50px;
		// background-color: #0081CC;
		// border-radius: 50%;
		// color: #ffffff;
		// margin-top: 18%;
		// margin-right: 44%;
	}

	.swiper-button-next:hover {
		// background-color: #ffffff;
		// color: #0081CC;
	}
</style>