<template>
	<div class="page_1_top" v-show="show_nav">
		<div class="page_1_top_top" @mouseenter="enter_tab()" @mouseleave="leave_tab()"
			:class="navBarFixed == true ? 'page_1_top_top_active' :''">
			<div class="top_left_title">
				<img class="top_left_title_img" v-if="!navBarFixed" @click="go_index()"
					src="../assets/images/logo_d.png" />
				<img class="top_left_title_img" v-else @click="go_index()" src="../assets/images/logo.png" />
			</div>
			<div class="top_right_box">
				<div class="t-right" @mouseenter="enter_item()" @mouseleave="leave_item()">
					<ul @click="">
						<li class="t-right-li" :class="item.active ? 't-right-li-a' : 't-right-li'"
							v-for="(item,index) in nav_list" :key="index" @mouseenter="enter_nav(item.id)"
							@mouseleave="leave_nav(item.id)" @click="go_page(item)">
							<div class="nav-tab">{{ item.name }}</div>
						</li>
						<el-button @click="go_contact()" v-if="!navBarFixed" class="btn-class">
							<span>Contact US</span>
							<img class="btn-img" src="../assets/images/next_2.png" alt="" />
						</el-button>
						<el-button @click="go_contact()" v-else class="btn-class-a">
							<span>Contact US</span>
							<img class="btn-img" src="../assets/images/next_2.png" alt="" />
						</el-button>
					</ul>
				</div>
			</div>
		</div>
		<div class="page_1_top_bottom" @mouseenter="enter_bottom()" @mouseleave="leave_bottom()">
			<div class="page_1_top_bottom_box" v-show="show_top">
				<div v-show="avtive_nav === 0" class="cent-box">
					<div class="cont-box">
						<div class="cont-img-item" v-for="(item,index) in solution_list" :key="index">
							<div class="cont-img">
								<img :src="item.img" @click="go_solut(item.id)" alt="" />
							</div>
							<div class="cont-text">
								{{ item.name }}
							</div>
						</div>

					</div>
				</div>
				<div v-show="avtive_nav === 1" class="cont-box_1">
					<div class="cont-box_1_left">
						<el-scrollbar style="white-space:nowrap;height: 370px;">
							<div class="nav-dev-type" v-for="(item,index) in device_list" :key="index"
								@mouseenter="change_type_1(item.id)">
								<div class="nav-dev-name" :style="acctive_cata_id === item.id ? 'color: #0784CD' : ''">
									{{ item.name }}
								</div>
								<div class="nav-dev-img" v-show="acctive_cata_id === item.id ? true : false">
									<img src="../assets/images/next_5.png" alt="" />
								</div>
							</div>
						</el-scrollbar>
					</div>

					<div class="cont-box_1_right">
						<div class="top-type-list">
							<div class="top-type-item"
								:style="acctive_type_id === item.id ? 'background-color: #0784CD;color: #FFFFFF;' : ''"
								v-for="(item,index) in show_device_type" :key="index"
								@mouseenter="change_type_2(item.id)">
								{{ item.name }}
							</div>
						</div>
						<div class="bot-dev-list">
							<div class="bot-dev-item" @click="get_device_detail(item)"
								v-for="(item,index) in show_device_device" :key="index">
								<div class="dev-img">
									<img class="dev-img-img" :src="item.img" alt="" />
								</div>
								<div class="dev-name">
									{{ item.name }}
									<!-- <img style="margin-left: 5px;" src="../assets/images/new.png" alt="" /> -->
								</div>
								<div class="dev-text">
									{{ item.msg }}
								</div>
							</div>

						</div>
					</div>
				</div>
				<div v-show="avtive_nav === 2" class="cent-box">
					<div class="cont-box-2">
						<div class="cont-2-list">
							<div class="top-nav-item" v-for="(item,index) in nav_box3_list" :key="index"
								@click="go_services(item.value)">
								<img class="nav-item-img" :src="item.img" alt="" />
								<div class="nav-item-text">
									<div class="item-top-text">
										{{ item.msg_1 }}
									</div>
									<div class="item-bot-text">
										{{ item.msg_2 }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getTreeApi,
		getDeviceList,
		getCarouselApi,
		getDeviceDetail
	} from '@/api/index';
	export default {
		name: 'PageNav',
		props: ["show_num"],
		created() {

		},
		data() {
			return {
				acctive_cata_id: 0,
				acctive_type_id: 10,
				show_top: false,
				navBarFixed: false,
				avtive_nav: 0,
				show_nav: true,
				solution_list: [{
						id: 0,
						name: "Mobile Payment",
						msg: "With the development of mobile internet technology and the popularization of smartphones, the convenient, fast, and secure mobile payment becoming common.We provide an overall mobile payment solution to help our partner start their own mobile payment business.",
						img: require('../assets/images/solut_0s.png'),
						active: true
					},
					{
						id: 1,
						name: "Smart Hospital",
						msg: "Through online service portal and Kiosks, to provide overall patient services covering pre-visit, during-visit and post-visit. Greatly reduce queuing time, simplify the payment and inquiry transactions, and provide personalized patient care, thus improve efficiency and patient satisfaction.",
						img: require('../assets/images/solut_1s.png'),
						active: false
					},
					{
						id: 2,
						name: "Smart Banking / Branch Transformation",
						msg: "Through online service portal and Kiosks, to proviThe smart branch solution provides a customer facing self-service portal, supporting around 300 banking transactions, and cross-platform monitoring and management functions, to improve efficiency and customer satisfaction, help branch focus more on customer service and marketing promotion.de overall patient services covering pre-visit, during-visit and post-visit. Greatly reduce queuing time, simplify the payment and inquiry transactions, and provide personalized patient care, thus improve efficiency and patient satisfaction.",
						img: require('../assets/images/solut_2s.png'),
						active: false
					},
					{
						id: 3,
						name: "Digital Taxation",
						msg: "Through online service portal and Kiosks, to proviThe smart branch solution provides a customer facing self-service portal, supporting around 300 banking transactions, and cross-platform monitoring and management functions, to improve efficiency and customer satisfaction, help branch focus more on customer service and marketing promotion.de overall patient services covering pre-visit, during-visit and post-visit. Greatly reduce queuing time, simplify the payment and inquiry transactions, and provide personalized patient care, thus improve efficiency and patient satisfaction.",
						img: require('../assets/images/solut_4s.png'),
						active: false
					}
				],
				show_device_type: [],
				show_device_device: [],
				device_list: [],

				nav_list: [{
						id: 0,
						name: "Solutions",
						value: "Solutions",
						active: false
					},
					{
						id: 1,
						name: "Products",
						value: "Products",
						active: false
					}, {
						id: 2,
						name: "Software Service",
						value: "Software Service",
						active: false
					},
					// {
					// 	id: 3,
					// 	name: "Industries",
					// 	value: "Industries",
					// 	active: false
					// },
					{
						id: 4,
						name: "Support",
						value: "Support",
						active: false
					},
					{
						id: 5,
						name: "About us",
						value: "About us",
						active: false
					}
				],
				nav_box3_list: [
					// {
					// 	id: 0,
					// 	img: require('../assets/images/nav_1.png'),
					// 	msg_1: "Application Marketplace",
					// 	msg_2: "Business loT Partners",
					// 	value: ""
					// },
					{
						id: 1,
						img: require('../assets/images/nav_2.png'),
						msg_1: "Terminal Management System",
						msg_2: "real-time & remote terminal services",
						value: "tms"
					},
					// {
					// 	id: 2,
					// 	img: require('../assets/images/nav_3.png'),
					// 	msg_1: "Remote Assit",
					// 	msg_2: "Business loT Partners",
					// 	value: ""
					// },
					// {
					// 	id: 3,
					// 	img: require('../assets/images/nav_4.png'),
					// 	msg_1: "Remote Key Injection",
					// 	msg_2: "Business loT Partners",
					// 	value: ""
					// },
					{
						id: 4,
						img: require('../assets/images/nav_5.png'),
						msg_1: "Mobile Payment Platform",
						msg_2: "one-stop mobile payment solution",
						value: "Mobile"
					},
					{
						id: 5,
						img: require('../assets/images/nav_6.png'),
						msg_1: "IoT Platform",
						msg_2: "cloud service to support daily business",
						value: ""
					}

				],

			}
		},
		watch: {
			show_num: {
				deep: true,
				// eslint-disable-next-line
				handler(nv, ov) {
					console.log("数据更新了")
					// this.updateSwiper()
				}
			}
		},
		created() {
			this.get_tree()
		},
		mounted() {
			// var _this = this;
			// setTimeout(function() {
			// 	_this.initSwiper()
			// }, 300)
			window.addEventListener("scroll", this.watchScroll); // 滚动吸顶
		},
		methods: {
			go_services(value) {
				if (value) {
					this.$router.push({
						path: '/services/' + value,
					})
				}
			},
			go_page(item) {
				if (item.name == "Solutions") {
					this.$router.push({
						path: '/solutions',
					})
				} else if (item.name == "About us") {
					this.$router.push({
						path: '/about',
					})
				} else if (item.name == "Support") {
					this.$router.push({
						path: '/support',
					})
				} else if (item.name == "Products") {
					this.$router.push({
						path: '/product',
					})
				}
			},
			get_tree() {
				this.init_data();
				// 关联产品
				getTreeApi().then((res) => {
					// console.log(res.data)
					// this.device_list = res.data
					// this.show_device_type = this.device_list[0].children
					// this.show_device_device = this.show_device_type[0].products
					// this.acctive_cata_id = this.device_list[0].id
					// this.acctive_type_id = this.show_device_type[0].id
				})

			},
			init_data() {
				let res = [{
						"id": "1763443968744308737",
						"name": "POS",
						"weight": 0,
						"children": [{
								"id": "1763449016777916417",
								"name": "Smart POS",
								"weight": 0,
								"products": [{
										"id": "1763463658535272449",
										"name": "KS8226",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/KS8226-S.png'),
										"weight": 0
									},
									{
										"id": "1769634300863442946",
										"name": "KS8223",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/KS8223-S.png'),
										"weight": 0
									}
								]
							},
							{
								"id": "1763448913581260801",
								"name": "Classic POS",
								"weight": 0,
								"products": [{
									"id": "1763458975557173250",
									"name": "i90",
									"description": null,
									"intro": null,
									"img": require('../assets/images/products/I90-S.png'),
									"weight": 0
								}]
							}
						]
					},
					{
						"id": "1769611078691016706",
						"name": "ECR",
						"weight": 0,
						"children": [{
								"id": "1769625492539731969",
								"name": "Desktop ECR",
								"weight": 0,
								"products": [{
									"id": "1769634619781541890",
									"name": "ZT8648",
									"description": null,
									"intro": null,
									"img": require('../assets/images/products/ZT8648-S.png'),
									"weight": 0
								}]
							},
							{
								"id": "1769611969489883138",
								"name": "Accessaries",
								"weight": 0,
								"products": [{
										"id": "8648646",
										"name": "ZTS-HR22",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZTS-HR22-S.png'),
										"weight": 0
									},
									{
										"id": "1516486",
										"name": "ZTS-FR20",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZTS-FR20-S.png'),
										"weight": 0
									},
									{
										"id": "386446486",
										"name": "ZTS-CPS70",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZTS-CPS70-S.png'),
										"weight": 0
									}
								]
							}
						]
					},
					{
						"id": "1763444826240401410",
						"name": "QR Terminals",
						"weight": 0,
						"children": [
							{
								"id": "1769612181889437698",
								"name": "QR Speaker",
								"weight": 0,
								"products": [{
										"id": "1770347105424515074",
										"name": "ZT8303",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/KS8303-S.png'),
										"weight": 0
									},
									{
										"id": "1770347165465976833",
										"name": "ZT8309",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT8309-S.png'),
										"weight": 0
									}
								]
							},{
								"id": "1769612244627836929",
								"name": "QR POS",
								"weight": 0,
								"products": [{
									"id": "1769635081465360386",
									"name": "ZT260",
									"description": null,
									"intro": null,
									"img": require('../assets/images/products/ZT260-S.png'),
									"weight": 0
								}]
							},
							
							{
								"id": "1769612520285884417",
								"name": "QR Reader",
								"weight": 0,
								"products": [{
										"id": "8648646",
										"name": "ZTS-HR22",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZTS-HR22-S.png'),
										"weight": 0
									},
									{
										"id": "1516486",
										"name": "ZTS-FR20",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZTS-FR20-S.png'),
										"weight": 0
									},
								]
							}
						]
					},
					{
						"id": "1763444904267038721",
						"name": "Kiosk",
						"weight": 0,
						"children": [
							{
								"id": "1769612871638536193",
								"name": "Freestanding",
								"weight": 0,
								"products": [{
										"id": "177035022031515645",
										"name": "ZT2220",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT2220-S.png'),
										"weight": 0
									},
									{
										"id": "17703502241515645",
										"name": "ZT2880",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT2880-S.png'),
										"weight": 0
									},
									{
										"id": "1568235476878411",
										"name": "ZT2618",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT2618-S.png'),
										"weight": 0
									}
								]
							},
							{
								"id": "1769612621167284225",
								"name": "Desktop",
								"weight": 0,
								"products": [{
										"id": "1769635277591015425",
										"name": "ZT8669",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT8669-S.png'),
										"weight": 0
									},
									{
										"id": "1770349934927159298",
										"name": "KS8123Plus",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/KS8123Plus-S.png'),
										"weight": 0
									}
								]
							},
							{
								"id": "1769612741761912833",
								"name": "Wall-mounted",
								"weight": 0,
								"products": [{
									"id": "1770346671842533377",
									"name": "ZT8669Plus",
									"description": null,
									"intro": null,
									"img": require('../assets/images/products/ZT8669Plus-S.png'),
									"weight": 0
								}]
							},
							{
								"id": "1769625951597916162",
								"name": "STM",
								"weight": 0,
								"products": [{
									"id": "1770346946275844097",
									"name": "ZT2214",
									"description": null,
									"intro": null,
									"img": require('../assets/images/products/ZT2214-S.png'),
									"weight": 0
								}]
							},
							{
								"id": "1769612929905807361",
								"name": "ATM / VTM",
								"weight": 0,
								"products": [{
										"id": "1770350153702055937",
										"name": "ZT6240",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT6240-S.png'),
										"weight": 0
									},
									{
										"id": "1770350220315992066",
										"name": "ZT6250",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT6250-S.png'),
										"weight": 0
									}
								]
							},
							
						]
					},
					{
						"id": "1763444946105221122",
						"name": "Encrypting PIN Pad",
						"weight": 0,
						"children": [{
								"id": "1769626295413403649",
								"name": "PCI",
								"weight": 0,
								"products": [{
										"id": "1770345997096460289",
										"name": "ZT588F",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT588F-S.png'),
										"weight": 0
									},
									{
										"id": "1770346087148167169",
										"name": "ZT598L",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT598L-S.png'),
										"weight": 0
									},
									{
										"id": "1770346167443922946",
										"name": "ZT598M",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT598M-S.png'),
										"weight": 0
									},
									{
											"id": "1770346312671698946",
											"name": "ZT598B",
											"description": null,
											"intro": null,
											"img": require('../assets/images/products/ZT598B-S.png'),
											"weight": 0
										},
									// {
									// 	"id": "1770346246070345730",
									// 	"name": "ZT598H",
									// 	"description": null,
									// 	"intro": null,
									// 	"img": require('../assets/images/products/ZT598H-S.png'),
									// 	"weight": 0
									// }
								]
							},
							{
								"id": "1769626339780751362",
								"name": "non-PCI",
								"weight": 0,
								"products": [
									{
										"id": "1770346400227794945",
										"name": "ZT599NM",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT599NM-S.png'),
										"weight": 0
									},
									{
										"id": "1770346473745555457",
										"name": "ZT599N",
										"description": null,
										"intro": null,
										"img": require('../assets/images/products/ZT599N-S.png'),
										"weight": 0
									}
								]
							}
						]
					},
					{
						"id": "1763444865004158978",
						"name": "PDA",
						"weight": 0,
						"children": [{
							"id": "1769625643379486721",
							"name": "Industrial PDA",
							"weight": 0,
							"products": [{
									"id": "1769635591522086914",
									"name": "ZT8211",
									"description": null,
									"intro": null,
									"img": require('../assets/images/products/ZT8211-S.png'),
									"weight": 0
								},
								{
									"id": "1769635705569406978",
									"name": "ZT8212",
									"description": null,
									"intro": null,
									"img": require('../assets/images/products/ZT8212-S.png'),
									"weight": 0
								},
								{
									"id": "1769635838264602625",
									"name": "ZT8213",
									"description": null,
									"intro": null,
									"img": require('../assets/images/products/ZT8213-S.png'),
									"weight": 0
								}
							]
						}]
					},
					{
						"id": "1763444768031850498",
						"name": "Fiscal Devices",
						"weight": 0,
						"children": [{
							"id": "1769627035871637506",
							"name": "Fiscal Device",
							"weight": 0,
							"products": [{
									"id": "1770345684998299650",
									"name": "ZT8350",
									"description": null,
									"intro": null,
									"img": require('../assets/images/products/ZT8350-S.png'),
									"weight": 0
								},
								{
									"id": "1770345777004552194",
									"name": "ZT8351",
									"description": null,
									"intro": null,
									"img": require('../assets/images/products/ZT8351-S.png'),
									"weight": 0
								}
							]
						}]
					},
					
				]
				this.device_list = res;
				// this.device_list = res.data
				this.show_device_type = this.device_list[0].children
				this.show_device_device = this.show_device_type[0].products
				this.acctive_cata_id = this.device_list[0].id
				this.acctive_type_id = this.show_device_type[0].id
			},
			// 滚动吸顶
			watchScroll() {
				// 当滚动超过 90 时，实现吸顶效果
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 90) {
					this.navBarFixed = true;
					if (scrollTop > 660) {
						this.navBarFixed = false;
						this.show_nav = false;
					}
				} else {
					this.show_nav = true;
					if (this.show_top) {
						return
					}
					this.navBarFixed = false;
				}
			},
			get_device_detail(item) {
				console.log(item)
				this.$router.push({
					path: '/' + item.name,
				})
				// getDeviceDetail(item.name).then(res=>{
				// 	console.log(res.data)
				// })
			},
			change_type_1(id) {
				this.acctive_cata_id = id;
				for (var i = 0; i < this.device_list.length; i++) {
					if (this.device_list[i].id === id) {
						this.show_device_type = this.device_list[i].children
						// this.show_device_device = this.device_list[i].children[0].children
						this.show_device_device = this.device_list[i].children[0].products
						this.acctive_type_id = this.device_list[i].children[0].id;
					}
				}
			},
			change_type_2(id) {
				this.acctive_type_id = id;
				for (var i = 0; i < this.show_device_type.length; i++) {
					if (this.show_device_type[i].id === id) {
						// this.show_device_device = this.show_device_type[i].children
						this.show_device_device = this.show_device_type[i].products
					}
				}
			},
			// 鼠标移入-导航栏
			enter_tab() {
				this.navBarFixed = true;
			},
			// 鼠标移出-导航栏
			leave_tab() {
				this.navBarFixed = false;
			},
			// 鼠标移入-标题
			enter_item() {
				this.show_top = true;
			},
			// 鼠标移出-标题
			leave_item() {
				this.show_top = false;
			},
			// 鼠标移入-导航
			enter_nav(id) {
				this.avtive_nav = id;
				for (var i = 0; i < this.nav_list.length; i++) {
					if (id == this.nav_list[i].id) {
						this.nav_list[i].active = true
					}
				}
				this.navBarFixed = true;
				this.show_top = true;
			},
			// 鼠标移出-导航
			leave_nav(id) {
				for (var i = 0; i < this.nav_list.length; i++) {
					if (id == this.nav_list[i].id) {
						this.nav_list[i].active = false
					}
				}
				this.show_top = false;
			},
			// 鼠标移入-底部
			enter_bottom() {
				for (var i = 0; i < this.nav_list.length; i++) {
					if (this.avtive_nav == this.nav_list[i].id) {
						this.nav_list[i].active = true
					}
				}
				this.navBarFixed = true;
				this.show_top = true;
			},
			// 鼠标移出-底部
			leave_bottom() {
				for (var i = 0; i < this.nav_list.length; i++) {
					this.nav_list[i].active = false;
				}
				this.show_top = false;
				this.navBarFixed = false;
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
				})
			},
			go_solut(id) {
				this.$router.push({
					path: '/solut-' + id,
					query: {
						id: '123456'
					},
				})

			},
			go_index() {
				this.$router.push({
					path: '/',
				})
				// window.location.href = "/";
			},
		},
	}
</script>
<style scoped lang="less">
	.page_1_top {
		width: 100%;
		min-width: 1380px;
		top: 0;
		position: fixed;
		z-index: 999;
		transition: all 0.1s; // 鼠标移出时menu瞬间消失，故将transition加在hover里

		.page_1_top_top {
			width: 100%;
			font-size: 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			// opacity: 0.9;
			color: #ffffff;
			padding-bottom: 4px;

		}

		.page_1_top_top_active {
			background-color: rgba(255, 255, 255, 1);
			color: #000000;
			box-shadow: 0 3px 8px 0 rgba(32, 42, 70, 0.07);

			.t-right {
				.nav-tab {
					color: #34364e;
				}

			}
		}


		.top_left_title {
			margin-left: 8%;
			height: 8vh;
			font-weight: bold;
			cursor: pointer;
			text-align: left;
			display: flex;
			align-items: center;
		}

		.top_left_title_img {
			height: 28px;
			width: 164px;
		}

		.top_left_title_text:hover {
			color: #81a7d6;
		}

		.top_right_box {
			margin-right: 8%;
			font-weight: bold;
			display: flex;

			.t-right {
				float: right;
				margin-right: 20px;

				ul {
					.t-right-li {
						float: left;
						height: 80px;
						cursor: pointer;

						.nav-tab {
							// width: 140px;
							height: 80px;
							margin: 0 auto;
							margin-left: 30px;
							margin-right: 30px;
							text-align: center;
							font-size: 18px;
							line-height: 80px;
							font-weight: 400;
							// background-color: aqua;
						}
					}

					.t-right-li-a {
						transition: all 0.1s; // 鼠标移出时menu瞬间消失，故将transition加在hover里

						.nav-tab {
							border-bottom: 4px solid #1890ff;
							transition: all 0.1s; // 鼠标移出时menu瞬间消失，故将transition加在hover里
							color: #2271f7;
						}
					}


					.btn-class {
						margin-top: 20px;
						margin-left: 20px;
						width: 130px;
						height: 38px;
						border-radius: 30px;
						border: 0;
						background-color: rgba(0, 0, 0, 0.4);
						color: #FFFFFF;

						.btn-img {
							margin-left: 10px;
							width: 7px;
							height: 8px;
						}
					}

					.btn-class-a {
						margin-top: 20px;
						margin-left: 20px;
						width: 130px;
						height: 38px;
						border-radius: 30px;
						border: 0;
						background: linear-gradient(316deg, #00B7E8 0%, #0081CC 100%);
						box-shadow: 0px 6px 20px 0px #ABDBF7;
						color: #FFFFFF;

						.btn-img {
							margin-left: 10px;
							width: 7px;
							height: 8px;
						}
					}

				}
			}
		}

		.page_1_top_bottom {
			background-color: rgba(255, 255, 255, 1);
			// opacity: 0.9;

			.page_1_top_bottom_box {
				background-color: #ffffff;


				.cent-box {
					width: 1200px;
					margin: 0 auto;
					padding-top: 60px;
					padding-bottom: 60px;

					.cont-box {

						width: 100%;
						display: flex;
						justify-content: space-between;

						.cont-img-item {
							overflow: hidden;
							display: flex;
							flex-direction: column;
							object-fit: cover; // 保持图片缩放时候不变形

							.cont-img {
								cursor: pointer;
								width: 285px;
								height: 192px;
								border-radius: 15px;
								overflow: hidden;
								// object-fit: cover; // 保持图片缩放时候不变形
								// img{
								// 	object-fit: cover; // 保持图片缩放时候不变形
								// }
							}

							.cont-text {
								margin-top: 20px;
								width: 100%;
								text-align: center;
								color: #000000;
								font-size: 14px;
							}
						}

						.cont-img-item:hover {
							.cont-img {
								overflow: hidden;

								img {
									transition: all 0.8s; //设置动画执行的时间为0.6s
									transform: scale(1.1); // 设置图片放大倍数
								}
							}
						}

					}

					.cont-box-2 {
						width: 100%;
						display: flex;
						flex-direction: column;

						.cont-2-list {
							width: 100%;
							display: flex;
							flex-wrap: wrap;

							.top-nav-item {
								cursor: pointer;
								width: 31%;
								height: 100px;
								display: flex;
								align-items: center;
								margin-right: 20px;
								margin-bottom: 10px;

								.nav-item-img {
									width: 56px;
									height: 56px;
									margin: 0 20px 0 30px;
								}

								.nav-item-text {
									flex: 1;
									display: flex;
									text-align: left;
									flex-direction: column;

									.item-top-text {
										color: #000000;
										font-size: 18px;
									}

									.item-bot-text {
										color: #999999;
										font-size: 12px;
									}
								}

							}

							.top-nav-item:hover {
								cursor: pointer;
								background-color: #F3FAFF;
							}
						}
					}
				}

				.cont-box_1 {
					display: flex;

					.cont-box_1_left {
						padding-top: 40px;
						padding-bottom: 60px;
						width: 40%;
						// height: 240px;
						// overflow: auto;
						background-color: #F6F6F6;
						display: flex;
						flex-direction: column;
						// justify-content: flex-end;

						.nav-dev-type {
							cursor: pointer;
							margin-top: 24px;
							margin-bottom: 24px;
							font-size: 18px;
							color: #000000;
							display: flex;
							align-items: center;

							.nav-dev-name {
								width: 86%;
								text-align: right;
							}

							.nav-dev-img {
								width: 14%;
								display: flex;
								align-items: center;
								justify-content: center;

								img {
									width: 13px;
									height: 10px;
									object-fit: cover; // 保持图片缩放时候不变形
								}
							}
						}

						.nav-dev-type:hover {
							color: #0784CD;

						}

					}

					.cont-box_1_right {
						width: 60%;
						padding-top: 60px;
						padding-bottom: 60px;
						background-color: #ffffff;
						display: flex;
						flex-direction: column;

						.top-type-list {
							width: 780px;
							// height: 50px;
							display: flex;
							flex-wrap: wrap;
							overflow: auto;
							margin-left: 60px;

							.top-type-item {
								cursor: pointer;
								padding: 9px 20px 9px 20px;
								margin-right: 20px;
								margin-bottom: 10px;
								background-color: #F6F6F6;
								color: #3C3C3C;
								font-size: 16px;
								display: flex;
								align-items: center;
								justify-content: center;
							}

							.top-type-item:hover {
								background-color: #0784CD;
								color: #FFFFFF;
							}

						}

						.bot-dev-list {
							width: 780px;
							height: 243px;
							// background-color: aqua;
							margin-left: 60px;
							margin-top: 50px;
							display: flex;
							flex-wrap: wrap;
							overflow: auto;

							// overflow-y: scroll;
							.bot-dev-item {
								width: 134px;
								display: flex;
								flex-direction: column;
								cursor: pointer;
								margin-right: 60px;
								margin-bottom: 30px;

								.dev-img {
									width: 134px;
									height: 134px;
									background-repeat: no-repeat;
									background-size: cover;
									background-position: center center;
									overflow: hidden;

									.dev-img-img {
										width: 100%;
										height: 100%;
										background-size: cover;
										object-fit: cover; // 保持图片缩放时候不变形
									}
								}

								.dev-name {
									margin-top: 20px;
									margin-bottom: 3px;
									width: 100%;
									display: flex;
									align-items: center;
									justify-content: center;
									color: #000000;
									font-size: 18px;

								}

								.dev-text {
									width: 80%;
									margin: 0 auto;
									display: flex;
									align-items: center;
									justify-content: center;
									text-align: center;
									font-size: 12px;
									color: #999999;
								}
							}

							.bot-dev-item:hover {
								overflow: hidden;

								.dev-img-img {
									transition: all 0.8s; //设置动画执行的时间为0.6s
									transform: scale(1.1); // 设置图片放大倍数
								}
							}
						}
					}
				}



			}
		}

	}
</style>
<style scoped>
	li {
		list-style-type: none;
	}

	/deep/ .el-scrollbar__wrap {
		overflow-x: hidden;
	}

	/*滚动条整体样式*/
	::-webkit-scrollbar {
		width: 2px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 10px;
	}

	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px;
		width: 2px;
		height: 60px;
		background: #0784CD;
	}

	::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		border-radius: 10px;
		background: rgba(0, 0, 0, 0);
	}
</style>